import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";
import Team2Elf from "components/Elf/Team2Elf/team2Elf";
import Team from "data/elf/team.json";
const Founder = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Team2Elf contentdata={Team} /> 
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Executive Heads</title>
      <meta key="description" name="description" 
      title="Meet Our Executive Leadership | Elfonze Technologies"
      content="Get to know the visionary leaders powering Elfonze Technologies. Uncover their proven track records, inspiring leadership styles, and the strategic roadmap fueling sustainable success." />
    </>
  )
}

export default Founder;
